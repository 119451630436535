<template>
  <div class="terminal-container-box">
    <div v-show="showTalk" class="left">
      <div class="title">
        所有会话
      </div>
      <!-- <div v-if="isReloadData">
        <div
          v-for="(item, i) in talkList"
          :key="i"
          class="list"
          :class="{ active: i === activeColor }"
          @dblclick="clickHandle(i,item)"
          @contextmenu.prevent="rightClick(item, $event)"
        >
          <svg-icon :icon-class="[i === activeColor ? 'talk-on' : 'talk']" />
          <span>{{ item.ip }}</span>
        </div>
      </div> -->
      <div>
        <div
          :key="i"
          class="list active"
        >
          <svg-icon icon-class="talk-on" />
          <span>HPC作业会话</span>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="control" @click="showHandle">
        <img src="@/assets/icon/arrow.png" alt="">
      </div>
    </div>
    <div class="right">
      <!-- <div class="header">
        <el-input v-model="hostname" placeholder="主机名" />
        <el-input v-model="port" class="port" placeholder="端口" />
        <el-input v-model="username" placeholder="用户名" />
        <el-input v-model="password" type="password" placeholder="密码" />
        <el-checkbox v-model="remember" label="记住会话" size="small" style="z-index:0;margin-right:1%" @change="rememberHandle" />
        <el-button @click="linkHandle"><svg-icon icon-class="link" />连接</el-button>
      </div> -->
      <div v-if="waitLoading" class="wait-loading">
        <div>
          <img src="@/assets/icon/terminal-loading.png" alt="">
          <div class="wait-loading-text">等待连接...</div>
        </div>
      </div>
      <!-- <TerminalBody v-else ref="terminalBody"
        box-class="terminal-container-box" :height="terminalHeight" login-type="Password" :base64="terminalBase64" path="/sshSocket/ws/vnc/ssh/terminal" @changeStatus="changeStatus" /> -->
      <TerminalBody
        ref="terminalBody"
        box-class="terminal-container-box"
        :height="terminalHeight"
        login-type="Job"
        :base64="terminalBase64"
        path="/sshSocket/ws/vnc/ssh/terminal"
        @changeStatus="changeStatus"
      />

    </div>
    <!-- <div v-show="menuVisible" id="contextmenu" class="sshMenu">
      <div class="contextmenu-item" @click="deleteSession(CurrentRow)">
        <svg-icon icon-class="uninstall" />
        删除
      </div>
    </div> -->
  </div>
</template>

<script>
import { addSession, querySession, deleteSession } from '@/api/application'
import store from '@/store'
import { ElMessage } from 'element-plus'
import TerminalBody from '@/components/TerminalBody.vue'
const Base64 = require('js-base64').Base64
export default {
  components: {
    TerminalBody
  },
  data() {
    return {
      row: 24,
      col: 80,
      waitLoading: true,
      remember: false,
      isReloadData: true,
      sessionSwitch: false,
      vncSwitch: false,
      loading: true,
      uId: '',
      default: '',
      connect: '',
      token: '',
      showTalk: true,
      hostname: '',
      port: '22',
      username: '',
      password: '',
      activeColor: 0,
      talkList: [],
      style: {
        height: '',
        width: ''
      },
      windowHeight: '',
      windowWidth: '',
      fitAddon: '',
      menuVisible: false,
      CurrentRow: '',
      textArea: null,
      terminalHeight: '100%',
      terminalBase64: ''
    }
  },
  watch: {
    hostname() {
      this.sessionSwitch = true
    },
    port() {
      this.sessionSwitch = true
    },
    username() {
      this.sessionSwitch = true
    },
    password() {
      this.sessionSwitch = true
    },
    talkList: {
      handler() {
        this.reload()
      },
      deep: true
    }
  },
  mounted() {
    this.uId = store.getters.uId
    // this.getSession()
    this.clickHandle(0)
  },
  methods: {
    changeStatus() {},
    // 记住密码
    rememberHandle(val) {
      if (val === true) {
        this.remember = true
      } else {
        this.remember = false
      }
      this.sessionSwitch = true
    },
    // 右键
    rightClick(row, event) {
      this.testModeCode = row.testModeCode
      this.menuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault() // 关闭浏览器右键默认事件
      this.CurrentRow = row
      var menu = document.querySelector('.sshMenu')
      this.styleMenu(menu, event)
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      window.removeEventListener('click', this.foo) // 关掉监听，
    },
    styleMenu(menu, event) {
      var y = event.clientY
      var x = event.clientX
      menu.style.left = x + 'px'
      window.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      menu.style.top = y + 'px'
    },
    // 删除会话
    deleteSession(row) {
      const listSessionId = []
      listSessionId.push(row.id)
      const data = {
        type: 'ssh',
        listSessionId: listSessionId
      }
      deleteSession(data).then((response) => {
        if (response.meta.status === 204) {
          ElMessage.success('删除成功')
          this.getSession()
        } else {
          ElMessage.error(response.meta.msg)
        }
      })
    },
    reload() {
      this.isReloadData = false
      this.$nextTick(() => {
        this.isReloadData = true
      })
    },
    getSession() {
      const data = {
        type: 'ssh',
        uid: this.uId
      }
      querySession(data).then((response) => {
        this.talkList = response.data
        this.loading = false
      })
    },
    async linkHandle() {
      // let Base64 = require('js-base64').Base64
      const reg = /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/
      const regPort = /^(([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5]))$/
      if (this.hostname === '' || this.username === '' || this.password === '' || this.port === '') {
        ElMessage.warning('主机名、端口、用户名、密码均不可为空！请填写或者选择会话！')
        return
      } else {
        if (!reg.test(this.hostname)) {
          ElMessage.warning('请填写正确的主机名！')
          return
        }
        if (!regPort.test(this.port)) {
          ElMessage.warning('请填写正确的端口号！')
          return
        }
      }
      const session = {
        ip: this.hostname,
        username: this.username,
        pwd: this.password,
        port: this.port,
        type: 'ssh',
        uid: this.uId,
        remember: this.remember.toString()
      }
      // 不需要每次都添加session ，只有当新输入的时候才添加
      if (this.sessionSwitch) {
        addSession(session).then((response) => {
          // console.log(response)
          // 添加session之后刷新左侧
          this.getSession()
        })
        this.sessionSwitch = false
      }
      const params = {
        host: this.hostname,
        port: Number(this.port),
        username: this.username,
        password: this.password
      }
      this.terminalBase64 = Base64.encode(JSON.stringify(params))
      this.waitLoading = false
      this.$nextTick(() => {
        this.$refs.terminalBody.dispose()
        this.$refs.terminalBody.init()
      })
    },
    clickHandle(i, item) {
      this.activeColor = i
      // this.hostname = item.ip
      // this.port = item.port
      // this.password = item.pwd
      // this.username = item.username
      const params = {
        token: sessionStorage.desktopToken,
        regionName: window.localStorage.regionName
      }
      this.terminalBase64 = Base64.encode(JSON.stringify(params))
      this.waitLoading = false
      this.$nextTick(() => {
        this.$refs.terminalBody.dispose()
        this.$refs.terminalBody.init()
      })
    },
    showHandle() {
      this.showTalk = !this.showTalk
      const that = this
      setTimeout(() => {
        that.fitAddon.fit()
      }, 100)
    }
  }
}
</script>
<style lang="less" scoped>
.terminal-container-box{
  display: flex;
  width: 100%;
  height: 100%;
  .sshMenu {
    position: fixed;
    background-color: #fff;
    width: 160px;
    padding: 0 10px;
    height: 31.5px;
    font-size: 12px;
    color: #313a46;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e8eef3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    white-space: nowrap;
    z-index: 10000;
    .svg-icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-right: 11px;
    }
  }
  .left{
    min-width: 240px;
    max-width: 400px;
    height: 100%;
    overflow: auto;
    color: #313A46;
    padding-top: 20px;
    padding-left: 20px;
    .active{
      background: #F2F9FF;
      color: #2385FF;
    }
    .title{
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      // color: #313A46;
      line-height: 25px;
      padding-bottom: 23px;
    }
    .list:hover{
      background: #F2F9FF;
      color: #2385FF;
    }
    .list {
      font-size: 14px;
font-family: PingFang SC;
font-weight: 500;
// color: #313A46;
padding: 6px 0 6px 6px;
span {
  vertical-align: middle;
}
    }
    .svg-icon {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
  .middle{
    width: 12px;
    height: 100%;
    background: #F5F5F5;
    border: 1px solid #E8E8E8;
    // padding-top: 20%;
    display: flex;
    align-items: center;
.control{
  width: 10px;
height: 80px;
padding-top: 24px;
// margin-top: 20%;
background: #A0E1F9;
}
  }
  .right{
    width: 100%;
    // min-width: 600px;
    min-width: calc(100% - 252px);
    .wait-loading{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .wait-loading-text{
          padding: 23px;
          font-size: 16px;
          color: #606060;
          text-align: center;
        }
      }
    .header{
      // display: flex;
      padding: 20px 0 20px 20px;
      line-height: 40px;
      ::v-deep .el-input {
          display: inline;
          margin-right: 1%;
          --el-input-focus-border: #dcdfe6;
        }
        ::v-deep .el-input__inner {
          display: inline;
          width: 20%;
          border-radius: 4px;
          height: 34px;
          line-height: 34px;
        }
        .port{
          ::v-deep .el-input__inner {
          width: 12%;
        }
        }
      ::v-deep .el-button {
      margin-right: 1%;
      background-color: #437aec;
      color: #fff;
      padding: 10px 12px 7px 12px;
      border-radius: 2px;
      min-height: 30px;
    }
    .svg-icon {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 6px;
    }
    }
  }
}
</style>

<style>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}
</style>
